.App {
  text-align: center;
}

.form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

a {
  text-decoration: none;
}

input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #28A891;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid #28A891;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-width: 2px;
  outline: none;
}